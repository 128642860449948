import React from 'react'
import { Layout, Table, Form, DatePicker, Input, Button } from 'antd'
import axios from 'axios'
import './orderList.scss'

const columns = [
    {
      title: '商品ID',
      dataIndex: 'productId',
      key: 'productId',
    },
    {
      title: '商品规格',
      dataIndex: 'productItemId',
      key: 'productItemId',
    },
    {
      title: '渠道类型',
      dataIndex: 'channelTypeStr',
      key: 'channelTypeStr',
    },
    {
      title: '成功订单数',
      dataIndex: 'goodsNum',
      key: 'goodsNum',
    },
    {
      title: '失败订单数',
      dataIndex: 'createTimeStr',
      key: 'createTimeStr',
    },
    {
      title: '总金额',
      dataIndex: 'orderNo',
      key: 'orderNo',
    }
  ];

class CustomeInfo extends React.Component {
  state = {
    lists: [],
  }

  records = [];

  getListData = (slects,page) => {
    var that = this;
    slects.page = page;
    axios.post(
      '/xjqy/recharge/getRechargeList',
      slects,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function(response) {
      page++;
      that.recordes = that.recordes.concat(response.data.data.records);
      if(that.recordes.length>=response.data.data.total){
        let data = that.state;
        data.lists = that.recordes;
        that.setState(data);
      }else that.getListData(page);
    })
    .catch(function(error) {
      console.log(error);
    })
  }

  componentDidMount() {
    this.recordes = [];
    this.getListData({rows:50},0);
  }

  handleSubmit = e => {
    this.recordes = [];
    var slects = {rows:50};
    if(typeof(this.refs.iput_time_width)!="undefined"&&typeof(this.refs.iput_time_width.picker.state.value[0])!="undefined")
    {
        slects.startCreateTime = this.refs.iput_time_width.picker.state.value[0].format('YYYY-MM-DD')+" 0:0:1";
        slects.endCreateTime = this.refs.iput_time_width.picker.state.value[1].format('YYYY-MM-DD')+" 23:59:59";
    }
    if(this.refs.iput_platform_id.rcSelect.state.value[0]!=="全部")
    {
        slects.channelType = this.refs.iput_platform_id.rcSelect.state.value[0];
    }
    if(this.refs.iput_custom_status.rcSelect.state.value[0]!=="全部状态")
    {
        slects.status = this.refs.iput_custom_status.rcSelect.state.value[0];
    }
    if(this.refs.iput_custum_orderid.state.value){
        slects.orderNo = this.refs.iput_custum_orderid.state.value;
    }
    if(this.refs.iput_app_id.state.value){
        slects.appId = this.refs.iput_app_id.state.value;
    }
    if(this.refs.iput_order_code.state.value){
        slects.tradeNo = this.refs.iput_order_code.state.value;
    }
    this.getListData(slects,0);
  }

  render() {
    return (
      <Layout
        style={{
          background: '#fff',
          padding: '20px 20px 0 5px',
        }}
      >
        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
          className="components-table-demo-control-bar"
          style={{ marginBottom: 16 }}
        >
          <Form.Item label="时间区间:">
            <DatePicker.RangePicker ref="iput_time_width" style={{ width: '220px' }} />
          </Form.Item>
          <Form.Item label="APPID:">
            <Input ref="iput_app_id" style={{ width: '180px' }} defaultValue="" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" style={{ width: '165px', }} htmlType="submit" value="1">
              立即查看
            </Button>
          </Form.Item>
        </Form>
        
        <Table
          className="ant-table-list"
          columns={columns}
          rowKey={row=>row.id}
          pagination={{ 
            pageSize: 7}}
          dataSource={this.state.lists}
        />
      </Layout>
    )
  }
}

export default CustomeInfo
