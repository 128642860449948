import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import App from './views/app'
import Login from './views/login'

const BasicRoute = () => (
  <HashRouter>
    <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/login" component={Login} />
    </Switch>
  </HashRouter>
)

export default BasicRoute
