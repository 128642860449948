import React from 'react'
import { Modal, Button, Layout, Table, Input, Select, Popconfirm, Form, message } from 'antd'
import axios from 'axios'
import './orderList.scss'

const { Option } = Select;

class CustomeList extends React.Component {
  state = {
    visible: false,
    lists: [],
  }

  columns =[];

  constructor(props: any) {
    super(props);
    this.columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },{
      title: '客户名称',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'AppID',
      dataIndex: 'customerAppId',
      key: 'customerAppId',
    },
    {
      title: '渠道类型',
      dataIndex: 'channelTypeStr',
      key: 'channelTypeStr',
    },
    {
      title: '渠道ID',
      dataIndex: 'channelAppId',
      key: 'channelAppId',
    },
    {
      title: '渠道KEY',
      dataIndex: 'channelAppSecret',
      key: 'channelAppSecret',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        return (
          <Popconfirm title="确认删除该商品?" onConfirm={() => this.handleDelete(record.id)}>
            <a href="/#" >删除</a>
          </Popconfirm>
        );
      },
    }
  ];

  }

  records = [];

  handleDelete = (key: React.Key) => {
      var that = this;
      axios.post(
        '/xjqy/customer/deleteCustomer',
        {
          id: key,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function(response) {
          var lists = [];
          let data = that.state;
          for(var i =0 ;i<data.lists.length;i++)
          {
              if(data.lists[i].id!==key)
              {
                  lists.push(data.lists[i])
              }
          }
          data.lists = lists;
          that.setState(data);
      })
      .catch(function(error) {
        message.info('删除失败，请联系系统管理员');
      })
  };

  handleSubmit = e => {
    var that = this;
    axios.post(
      '/xjqy/customer/addCustomer',
      {
        channelAppId: this.refs.channelAppId.state.value,
        channelAppSecret: this.refs.channelAppSecret.state.value,
        channelType: this.refs.channelType.rcSelect.state.value[0],
        customerAppId: this.refs.customerAppId.state.value,
        customerName:this.refs.customerName.state.value
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function(response) {
        if(response.data.status!==200)
        {
          message.info('创建失败，请联系系统管理员');
        }
        else
        {
          let data = that.state;
          var records = data.lists.concat(response.data.data);
          data.lists = records;
          data.visible = false;
          that.setState(data);
        }
    })
    .catch(function(error) {
      message.info('创建失败，请联系系统管理员');
    })
  }

  addProduct = () => {
    this.setState({
      visible: true,
    });
  }

  hideModal = () => {
    this.setState({
      visible: false,
    });
  }

  getListData = (slects,page) => {
    var that = this;
    slects.page = page;
    axios.post(
      '/xjqy/customer/getCustomerList',
      slects,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function(response) {
      page++;
      that.recordes = that.recordes.concat(response.data.data.records);
      if(that.recordes.length>=response.data.data.total){
        let data = that.state;
        data.lists = that.recordes;
        that.setState(data);
      }else that.getListData(page);
    })
    .catch(function(error) {
      console.log(error);
    })
  }

  componentDidMount() {
    this.recordes = [];
    this.getListData({rows:50},0);
  }

  render() {
    return (
      <>
        <Layout
          style={{
            background: '#fff',
            padding: '20px 20px 0 5px',
          }}
        >
          <Button type="primary" onClick={this.addProduct} style={{ marginLeft: 10,marginBottom:16,width:80}}>
            添加
          </Button>
          <Table
            className="ant-table-list"
            columns={this.columns}
            rowKey={row=>row.id}
            pagination={{ 
              pageSize: 7}}
            dataSource={this.state.lists}
          />
        </Layout>
        <Modal
            title="添加新客户"
            visible={this.state.visible}
            onOk={this.hideModal}
            onCancel={this.hideModal}
            okText="添加"
            cancelText="取消"
            width={400}
            footer={[]}
          >
            <Form
              layout="inline"
              onSubmit={this.handleSubmit}
              className="components-table-demo-control-bar"
              style={{ marginLeft: 16 }}
            >
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;客户名称:">
                <Input ref="customerName" style={{ width: '180px' }} defaultValue="" />
              </Form.Item>
              <Form.Item label="&nbsp;&nbsp;&nbsp;客户APPID:">
                <Input ref="customerAppId" style={{ width: '180px' }} defaultValue="" />
              </Form.Item>
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;渠道:">
                <Select ref="channelType" defaultValue="全部" style={{ width: '180px' }}>
                  <Option value={1}>盎司</Option>
                  <Option value={2}>皓之成</Option>
                  <Option value={3}>比高(直充)</Option>
                  <Option value={4}>比高(卡密)</Option>
                  <Option value={5}>琅琅(直充)</Option>
                  <Option value={6}>琅琅(卡密)</Option>
                </Select>
              </Form.Item>
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;渠道ID:">
                <Input ref="channelAppId" style={{ width: '180px' }} defaultValue="" />
              </Form.Item>
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;渠道KEY:">
                <Input ref="channelAppSecret" style={{ width: '180px' }} defaultValue="" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" style={{ width: '120px', marginLeft:'105px', marginTop:'30px'}} htmlType="submit" value="1">
                  立即添加
                </Button>
              </Form.Item>
            </Form>
        </Modal>
      </>
    )
  }
}

export default CustomeList
