import React, { Component } from 'react'
import { setCookie } from '../utils/cookies'
import axios from 'axios'
import { Form, Icon, Input, Button, message } from 'antd'
import './login.scss'
const FormItem = Form.Item

class NormalLoginForm extends Component {
  state = {
    isLoding: false,
  }
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let that = this
        axios
          .post(
            '/xjqy/common/login',
            {
              userName: values.username,
              password: values.password,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(function(response) {
            if (response.data.status === 200) {
              console.log(response.data.data.toString());
              setCookie('PERMISSION_TOKEN',JSON.stringify(response.data.data))
              setTimeout(function() {
                //延迟进入
                that.props.history.push({ pathname: '/', state: values })
              }, 300)
            }else message.info(response.data.message);
          })
          .catch(function(error) {
            console.log(error)
          })
      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div className="login-container">
        <div className="login-bg-logo"></div>
        <div className="login-bg-right"></div>
        <div className="login-bg-left"></div>
        <div className="login-form">
          <div className="login-text-h1">WELCOME</div>
          <div className="login-text-h2">国动权益系统</div>
          <div>请输入账号密码登录系统</div>
          <Form
            onSubmit={this.handleSubmit}
            style={{ maxWidth: '420px', minWidth: '380px' }}
          >
            <FormItem>
              {getFieldDecorator('username', {
                rules: [{ required: true, message: '请输入用户名!' }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ fontSize: 16 }} />}
                  placeholder="请输入用户名"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: '请输入密码!' }],
              })(
                <Input.Password
                  prefix={<Icon type="lock" style={{ fontSize: 16 }} />}
                  placeholder="请输入密码"
                />
              )}
            </FormItem>
            <FormItem style={{ marginBottom: '0' }}>
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                className="login-form-button"
                size="large"
                style={{
                  width: '100%',
                  height: '64px',
                  backgroundColor: '#458FF7',
                }}
              >
                登录
              </Button>
            </FormItem>
          </Form>
        </div>

        <footer className="footer">
          <div className="login-container__footer">
            <p className="login-container__footer__item"><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010702007152">沪公网安备31010702007152号</a></p>
            <p className="login-container__footer__item"><a href="https://beian.miit.gov.cn">沪ICP备2021026718号-1</a></p>
            <p className="login-container__footer__item">copyright © 上海晓见网络科技有限公司</p>
          </div>
        </footer>
      </div>
    )
  }
}

const Login = Form.create()(NormalLoginForm)
export default Login
