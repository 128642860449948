import React, { Component } from 'react'
import { getCookie } from '../utils/cookies'
import { Dropdown, Layout, Avatar, Icon, Menu } from 'antd'
import Content from './components/content'
import './app.scss'

const { Header } = Layout
const menu = (
  <Menu>
    <Menu.Item>
      <a
        target="_self"
        rel="noopener noreferrer"
        href="http://8.136.221.34/#/login"
      >
        退出登录
      </a>
    </Menu.Item>
  </Menu>
)

export default class App extends Component {
  state = {
    username:"user",
    lists: [],
  }

  componentDidMount() {
    var token = getCookie('PERMISSION_TOKEN');
    if(token){
        let data = this.state;
        data.username = JSON.parse(token).userName;
        this.setState(data);
    }
    else this.props.history.push({ pathname: '/login', state: 1 })
  }

  render() {
    return (
      <div className="app-container">
        <Layout>
          <Header className="app-header">
            <div className="app-logo"></div>
            <div className="app-info">
              <span>
                <Avatar icon="user" />
              </span>
              <span style={{ marginLeft: 10 }}>
                <Dropdown overlay={menu}>
                  <a style={{ color: '#fff' }} href="test">
                    {this.state.username}
                    <Icon type="caret-down" style={{ marginLeft: 10 }} />
                  </a>
                </Dropdown>
              </span>
            </div>
          </Header>
          <Layout>
            <Content lists={this.state.lists} />
          </Layout>
        </Layout>
      </div>
    )
  }
}
