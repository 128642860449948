import React from 'react'
import { Layout, Icon, Input, Form, Button, Radio, message } from 'antd'
import axios from 'axios'
const FormItem = Form.Item

class UserContent extends React.Component {
  state = {
    
  }

  handleSubmit = e => {
    if(this.refs.passwordItem.state.value===this.refs.passwordRepeat.state.value)
    {
      axios
        .post(
          '/xjqy/user/addUser',
          {
            userName: this.refs.usernameItem.state.value,
            password: this.refs.passwordItem.state.value,
            userRange:this.refs.typwRadio.state.value
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then(function(response) {
            message.info(response.data.message);
        })
        .catch(function(error) {
          message.info('创建失败，请联系系统管理员');
        })
    }else{

    }
  }

  render() {
    return (
      <Layout
        style={{
          background: '#fff',
          padding: '30px 0 0 45px',
        }}
      >
        <Form
              onSubmit={this.handleSubmit}
              style={{ width: '500px'}}
            >
          <Form.Item>
            <Input ref="usernameItem" style={{ width: '350px'}} prefix={<Icon type="user" style={{ fontSize: 16 }} />}
                  placeholder="请输入用户名"/>
          </Form.Item>
          <Form.Item>
            <Input ref="passwordItem"  style={{ width: '350px'}} prefix={<Icon type="lock" style={{ fontSize: 16 }} />}
                  placeholder="请输入密码"/>
          </Form.Item>
          <Form.Item>
            <Input ref="passwordRepeat" style={{ width: '350px'}} prefix={<Icon type="lock" style={{ fontSize: 16 }} />}
                  placeholder="请确认密码"/>
          </Form.Item>
          <Form.Item label="请选择账号类型" name="type">
            <Radio.Group ref="typwRadio">
              <Radio value="user">普通用户</Radio>
              <Radio value="money">财务</Radio>
              <Radio value="admin">管理员</Radio>
            </Radio.Group>
          </Form.Item>
          <FormItem style={{ marginBottom: '0' }}>
            <Button style={{ marginLeft: '110px'}} type="primary" htmlType="submit">
              添加用户
            </Button>
          </FormItem>
        </Form>
      </Layout>
    )
  }
}

export default UserContent
