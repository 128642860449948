import React from 'react'
import { Menu, Layout, Icon } from 'antd'
import Tabs from './tabs'
import { getCookie } from '../../utils/cookies'

const { SubMenu } = Menu
const { Sider } = Layout

class BaseContent extends React.Component {
  state = {
    lists: [],
    selects: [{title:"订单管理",url:"",type:1,key:"1"}],
    selectKey: '1',
  }

  componentDidUpdate() {
    var menus = [{title:"订单管理",url:"",type:1,key:"1"}];
    var token = getCookie('PERMISSION_TOKEN');
    if(token){
        if(JSON.parse(token).userRange.split("|^")[0]==="admin")
        {
          menus = [{title:"订单管理",type:1,key:"1"},{title:"产品信息",type:2,key:"2"},{title:"客户管理",type:3,key:"3"},{title:"财务对账",type:4,key:"4",child:[{title:"渠道对账",type:6,key:"6"},{title:"客户对账",type:7,key:"7"}]},{title:"系统管理",type:8,key:"8",child:[{title:"用户管理",type:9,key:"9"}]}];
        }
        else if(JSON.parse(token).userRange.split("|^")[0]==="money")
        {
          menus = [{title:"订单管理",type:1,key:"1"},{title:"财务对账",type:4,key:"4",child:[{title:"渠道对账",type:6,key:"6"},{title:"客户对账",type:7,key:"7"}]}];
        }
    }
    let data = this.state;
    if (data.selectKey !== this.props.selectKey) {
      data.selectKey = this.props.selectKey
      data.lists = menus;
      this.setState(data)
    }
  }

  recursion(dataSource) {
    return dataSource.map(menu => {
      if (menu.child) {
        return (
          <SubMenu
            key={menu.key}
            title={
              <span>
                <Icon type="mail" />
                <span>{menu.title}</span>
              </span>
            }
          >
            {this.recursion(menu.child)}
          </SubMenu>
        )
      } else {
        return (
          <Menu.Item
            key={menu.key}
            title={menu.title}
            type={menu.type}
          >
            <Icon type="pie-chart" />
            <span>{menu.title}</span>
          </Menu.Item>
        )
      }
    })
  }

  handleTabSelect = newValue => {
    let tmpstate = this.state
    var i
    if (newValue.indexOf('del_') >= 0) {
      for (i = 0; i < tmpstate.selects.length; i++) {
        if (tmpstate.selects[i].key === newValue.split('del_')[1]) {
          tmpstate.selects.splice(i, 1)
          break
        }
      }
      this.setState(tmpstate)
    } else if (newValue.indexOf('sel_') >= 0) {

    }
  }

  handleClick = e => {
    let data = this.state
    let canFind = false
    for (var i = 0; i < data.selects.length; i++) {
      if (data.selects[i].key === e.key) {
        canFind = true
      }
    }
    if (!canFind) {
      data.selects.push({
        title: e.item.props.title,
        key: e.key,
        type: e.item.props.type,
      })
    }
    data.selectKey = e.key;
    this.setState(data)
    this.handleTabSelect('sel_' + e.key)
  }

  render() {
    return (
      <Layout
        style={{
          minHeight: 'calc(100vh - 104px)',
        }}
      >
        <Sider
          style={{
            background: '#fff',
            overflow: 'auto',
            height: 'calc(100vh - 104px)',
          }}
        >
          <Menu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['3','8']}
            mode="inline"
            onClick={this.handleClick}
          >
            {this.recursion(this.state.lists)}
          </Menu>
        </Sider>
        <Layout>
          <Layout style={{background: '#fff',}}>
            <Tabs style={{height:'40px'}}
                  subClick={this.handleTabSelect}
                  lists={this.state.selects}
                  selectKey = {this.state.selectKey} />
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

export default BaseContent
