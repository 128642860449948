import React from 'react'
import { Icon, Input,Tabs } from 'antd'
import './tabs.scss'

import UserCtrl from './userCtrl'
import OrderList from './orderList'
import ProductList from './productList'
import CustomeList from './customeList'
import CustomeInfo from './customeInfo'
import ChannelInfo from './channelInfo'

const { TabPane } = Tabs

class BaseTabs extends React.Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 0
    const panes = props.lists
    
    this.state = {
      activeKey: this.props.lists.length > 0 ? this.props.lists[0].key : '',
      panes,
    }
    this.onChange = this.onChange.bind(this)
  }

  componentDidUpdate() {
    let data = this.state
    if(this.props.selectKey){
      data.panes = this.props.lists
      data.activeKey = this.props.selectKey
      this.setState(data)
    }
  }

  onChange = activeKey => {
    //this.props.subClick('sel_' + activeKey)
    this.setState({ activeKey })
  }

  onEdit = (targetKey, action) => {
    this[action](targetKey)
  }

  remove = targetKey => {
    this.props.subClick('del_' + targetKey)
    let { activeKey } = this.state
    let lastIndex
    this.state.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1
      }
    })
    const newPanes = this.state.panes.filter(pane => pane.key !== targetKey)
    if (newPanes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        activeKey = newPanes[lastIndex].key
      } else {
        activeKey = newPanes[0].key
      }
    }
    this.setState({
      panes: newPanes,
      activeKey: activeKey,
    });
  }

  setPage(dataSource){
    if(dataSource){
      if(Number(dataSource)===1)
      {
        return(
          <OrderList/>
        )
      }else if(Number(dataSource)===2)
      {
        return(
          <ProductList/>
        )
      }else if(Number(dataSource)===3)
      {
        return(
          <CustomeList/>
        )
      }
      else if(Number(dataSource)===7)
      {
        return(
          <CustomeInfo/>
        )
      }
      else if(Number(dataSource)===6)
      {
        return(
          <ChannelInfo/>
        )
      }
      else if(Number(dataSource)===9)
      {
        return(
          <UserCtrl/>
        )
      }else{
        return(
          <Input
            prefix={<Icon type="user" style={{ fontSize: 16 }} />}
                placeholder="请输入用户名"
          />
        )
      }
    }
  }

  render() {
    return (
      <Tabs
        type="editable-card"
        className="tabs-container"
        activeKey={this.state.activeKey}
        onChange={this.onChange}
        onEdit={this.onEdit}
        hideAdd
      >
        {this.state.panes.map(pane => (
          <TabPane 
            tab={pane.title} 
            key={pane.key}>
            {this.setPage(pane.key)}
          </TabPane>
        ))}
      </Tabs>
    )
  }
}

export default BaseTabs
