import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import store from './redux/store'
import Router from './router'

const renderApp = () => {
  ReactDOM.render(
    <ConfigProvider locale={zhCN}>
      <Provider store={store}>
        <Router />
      </Provider>
    </ConfigProvider>,
    document.getElementById('root')
  )
}

renderApp()

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./views/app', renderApp)
}
