import React from 'react'
import { Layout, Table, Form, DatePicker, Input, Button, Icon, Select } from 'antd'
import axios from 'axios'
import './orderList.scss'

import ExportJsonExcel from 'js-export-excel';

const { Option } = Select;

const columns = [
    {
      title: '渠道订单号',
      dataIndex: 'tradeNo',
      key: 'tradeNo',
    },
    {
      title: 'APPID',
      dataIndex: 'appId',
      key: 'appId',
    },
    {
      title: '渠道类型',
      dataIndex: 'channelTypeStr',
      key: 'channelTypeStr',
    },
    {
      title: '商品ID',
      dataIndex: 'productId',
      key: 'productId',
    },
    {
      title: '商品规格',
      dataIndex: 'productItemId',
      key: 'productItemId',
    },
    {
      title: '购买数量',
      dataIndex: 'goodsNum',
      key: 'goodsNum',
    },
    {
      title: '订单时间',
      dataIndex: 'createTimeStr',
      key: 'createTimeStr',
    },
    {
      title: '客户订单号',
      dataIndex: 'orderNo',
      key: 'orderNo',
    },
    {
      title: '充值状态',
      key: 'statusStr',
      dataIndex: 'statusStr',
    }
  ];

class OrderList extends React.Component {
  state = {
    lists: [],
  }

  records = [];

  getListData = (slects,page) => {
    var that = this;
    slects.page = page;
    axios.post(
      '/xjqy/recharge/getRechargeList',
      slects,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function(response) {
      page++;
      that.recordes = that.recordes.concat(response.data.data.records);
      if(that.recordes.length>=response.data.data.total){
        let data = that.state;
        data.lists = that.recordes;
        that.setState(data);
      }else that.getListData(slects,page);
    })
    .catch(function(error) {
      console.log(error);
    })
  }

  componentDidMount() {
    this.recordes = [];
    var slects = {rows:50};
    var date = new Date();       
    var mon = date.getMonth() + 1;       
    var day = date.getDate();
    var currDate = date.getFullYear() + "-"+ (mon<10?"0"+mon:mon) + "-"+(day<10?"0"+day:day);
    slects.startCreateTime = currDate+" 0:0:1";
    slects.endCreateTime = currDate+" 23:59:59";
    console.log(slects);
    this.getListData(slects,0);
  }

  downloadExcel = () => {
    const datas = this.state.lists ? this.state.lists : [];
    var option={};
    let dataTable = [];
    if (datas) {
    for (const data in datas) {
        if(data){
          let obj = {
            id: data.id,
            orderNo: data.orderNo,
            tradeNo: data.tradeNo,
            appId: data.appId,
            channelTypeStr: data.channelTypeStr,
            sendTimeStr: data.sendTimeStr,
            productId: data.productId,
            productItemId: data.productItemId,
            goodsNum: data.goodsNum,
            rechargeAccount: data.rechargeAccount,
            customerPhone: data.customerPhone,
            thirdOrderNo: data.thirdOrderNo,
            statusStr: data.statusStr,
            notifyUrl: data.notifyUrl,
            remark: data.remark
          }
          dataTable.push(obj);
        }
      }
    }
    option.fileName = '订单列表'
    option.datas=[
      {
        sheetData:datas,
        sheetName:'sheet',
        sheetFilter:['id','orderNo','tradeNo','appId','channelTypeStr','sendTimeStr','productId','productItemId','goodsNum','rechargeAccount','customerPhone','thirdOrderNo','statusStr','notifyUrl','remark'],
        sheetHeader:['ID','客户订单号','渠道订单号','APPID','渠道名称','订单发起时间','商品ID','商品类型','商品数量','充值帐户','客户手机号','第三方订单号','订单状态','回调地址','备注'],
      }
    ];

    var toExcel = new ExportJsonExcel(option); 
    toExcel.saveExcel();
  }

  handleSubmit = e => {
    this.recordes = [];
    var slects = {rows:50};
    if(typeof(this.refs.iput_time_width)!="undefined"&&typeof(this.refs.iput_time_width.picker.state.value[0])!="undefined")
    {
        slects.startCreateTime = this.refs.iput_time_width.picker.state.value[0].format('YYYY-MM-DD')+" 0:0:1";
        slects.endCreateTime = this.refs.iput_time_width.picker.state.value[1].format('YYYY-MM-DD')+" 23:59:59";
    }
    if(this.refs.iput_platform_id.rcSelect.state.value[0]!=="全部")
    {
        slects.channelType = this.refs.iput_platform_id.rcSelect.state.value[0];
    }
    if(this.refs.iput_custom_status.rcSelect.state.value[0]!=="全部状态")
    {
        slects.status = this.refs.iput_custom_status.rcSelect.state.value[0];
    }
    if(this.refs.iput_custum_orderid.state.value){
        slects.orderNo = this.refs.iput_custum_orderid.state.value;
    }
    if(this.refs.iput_app_id.state.value){
        slects.appId = this.refs.iput_app_id.state.value;
    }
    if(this.refs.iput_order_code.state.value){
        slects.tradeNo = this.refs.iput_order_code.state.value;
    }
    this.getListData(slects,0);
  }

  render() {
    return (
      <Layout
        style={{
          background: '#fff',
          padding: '20px 20px 0 5px',
        }}
      >
        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
          className="components-table-demo-control-bar"
          style={{ marginBottom: 16 }}
        >
          <Form.Item label="渠道订单号:">
            <Input ref="iput_order_code" style={{ width: '180px' }} defaultValue="" />
          </Form.Item>
          <Form.Item label="时间区间:">
            <DatePicker.RangePicker ref="iput_time_width" style={{ width: '220px' }} />
          </Form.Item>
          <Form.Item label="渠道:">
            <Select ref="iput_platform_id" defaultValue="全部" style={{ width: '180px' }}>
              <Option value={1}>盎司</Option>
              <Option value={2}>皓之成</Option>
              <Option value={3}>比高(直充)</Option>
              <Option value={4}>比高(卡密)</Option>
              <Option value={5}>琅琅(直充)</Option>
              <Option value={6}>琅琅(卡密)</Option>
            </Select>
          </Form.Item>
          <Form.Item label="APPID:">
            <Input ref="iput_app_id" style={{ width: '180px' }} defaultValue="" />
          </Form.Item>
          <Form.Item label="客户订单号:">
            <Input ref="iput_custum_orderid" style={{ width: '180px' }} defaultValue="" />
          </Form.Item>
          <Form.Item label="订单状态:">
            <Select ref="iput_custom_status" defaultValue="全部状态" style={{ width: '220px' }}>
              <Option value={1}>未充值</Option>
              <Option value={2}>充值中</Option>
              <Option value={3}>充值成功</Option>
              <Option value={4}>充值失败</Option>
              <Option value={5}>订单不存在</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" style={{ width: '165px', }} htmlType="submit" value="1">
              立即搜索
            </Button>
            <Button type="primary" onClick={this.downloadExcel} style={{ marginLeft: 22 }}>
              <Icon type="download"/>
            </Button>
          </Form.Item>
        </Form>
        
        <Table
          className="ant-table-list"
          columns={columns}
          rowKey={row=>row.id}
          pagination={{ 
            pageSize: 7}}
          dataSource={this.state.lists}
        />
      </Layout>
    )
  }
}

export default OrderList
