import React from 'react'
import { Modal, Button, Layout, Table, Input, Select, Popconfirm, Form, message } from 'antd'
import axios from 'axios'
import './orderList.scss'

const { Option } = Select;

class ProductList extends React.Component {
  state = {
    visible: false,
    mvisible: false,
    lists: [],
  }

  columns = [];
  pruductData = null;

  constructor(props: any) {
    super(props);
    this.columns = [
    {
      title: '产品名称',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: '商品ID',
      dataIndex: 'productId',
      key: 'productId',
    },
    {
      title: '渠道类型',
      dataIndex: 'channelTypeStr',
      key: 'channelTypeStr',
    },
    {
      title: '渠道商品ID',
      dataIndex: 'channelProductId',
      key: 'channelProductId',
    },
    {
      title: '商品规格',
      dataIndex: 'channelProductItemId',
      key: 'channelProductItemId',
    },
    {
      title: '运营商',
      dataIndex: 'operatorsId',
      key: 'operatorsId',
    },
    {
      title: '充值面值',
      dataIndex: 'faceValue',
      key: 'faceValue',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        return (
          <>
            <Popconfirm title="确认删除该商品?" onConfirm={() => this.handleDelete(record.id)}>
              <a href="/#" >删除</a>
            </Popconfirm>
          </>
        );
      },
    }
  ];

  }

  /*&nbsp;&nbsp;&nbsp;&nbsp;
            <Popconfirm title="确认修改该商品?" onConfirm={() => this.handleModify(record)}>
              <a href="/#" >修改</a>
            </Popconfirm>*/

  records = [];

  handleModify = (item:any) => {
    this.pruductData = item;
    this.setState({
      mvisible: true,
    });
  }

  hideModifyModal = () => {
    this.setState({
      mvisible: false,
    });
  }

  handleDelete = (key: React.Key) => {
    var that = this;
    axios.post(
      '/xjqy/product/deleteProduct',
      {
        id: key,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function(response) {
        var lists = [];
        let data = that.state;
        for(var i =0 ;i<data.lists.length;i++)
        {
            if(data.lists[i].id!==key)
            {
                lists.push(data.lists[i])
            }
        }
        data.lists = lists;
        that.setState(data);
    })
    .catch(function(error) {
      message.info('删除失败，请联系系统管理员');
    })
  };

  handleSubmit = e => {
    var that = this;
    axios.post(
      '/xjqy/product/addProduct',
      {
        channelProductId: this.refs.channelProductId.state.value,
        channelProductItemId: this.refs.channelProductItemId.state.value,
        channelType: this.refs.channelType.rcSelect.state.value[0],
        productId: this.refs.productId.state.value,
        productName:this.refs.productName.state.value,
        faceValue:this.refs.faceValue.state.value,
        operatorsId:this.refs.operatorsId.state.value,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function(response) {
        if(response.data.status!==200)
        {
          message.info('创建失败，请联系系统管理员');
        }
        else
        {
          let data = that.state;
          var records = data.lists.concat(response.data.data);
          data.lists = records;
          data.visible = false;
          that.setState(data);
        }
    })
    .catch(function(error) {
      message.info('创建失败，请联系系统管理员');
    })
  }

  handleModifySubmit = e => {
    axios.post(
      '/xjqy/product/updateProduct',
      {
        channelProductId: this.refs.mchannelProductId.state.value,
        channelProductItemId: this.refs.mchannelProductItemId.state.value,
        channelType: this.refs.mchannelType.rcSelect.state.value[0],
        productId: this.pruductData.productId
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function(response) {
        if(response.data.status!==200)
        {
          message.info('创建失败，请联系系统管理员');
        }
        else
        {
          
        }
    })
    .catch(function(error) {
      message.info('创建失败，请联系系统管理员');
    })
  }

  addProduct = () => {
    this.setState({
      visible: true,
    });
  }

  hideModal = () => {
    this.setState({
      visible: false,
    });
  }

  getListData = (slects,page) => {
    var that = this;
    axios.post(
      '/xjqy/product/getProductList',
      slects,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(function(response) {
      page++;
      that.recordes = that.recordes.concat(response.data.data.records);
      if(that.recordes.length>=response.data.data.total){
        let data = that.state;
        data.lists = that.recordes;
        that.setState(data);
      }else that.getListData(page);
    })
    .catch(function(error) {
      console.log(error);
    })
  }

  componentDidMount() {
    this.recordes = [];
    this.getListData({rows:50},0);
  }

  render() {
    return (
      <>
        <Layout
          style={{
            background: '#fff',
            padding: '20px 20px 0 5px',
          }}
        >
          <Button type="primary" onClick={this.addProduct} style={{ marginLeft: 10,marginBottom:16,width:80}}>
            添加
          </Button>
          <Table
            className="ant-table-list"
            columns={this.columns}
            rowKey={row=>row.id}
            pagination={{ 
              pageSize: 7}}
            dataSource={this.state.lists}
          />
        </Layout>
        <Modal
            title="添加新产品"
            visible={this.state.visible}
            onOk={this.hideModal}
            onCancel={this.hideModal}
            okText="添加"
            cancelText="取消"
            width={350}
            footer={[]}
          >
            <Form
              layout="inline"
              onSubmit={this.handleSubmit}
              className="components-table-demo-control-bar"
              style={{ marginLeft: 16 }}
            >
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;产品名称:">
                <Input ref="productName" style={{ width: '180px' }} defaultValue="" />
              </Form.Item>
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;渠道:">
                <Select ref="channelType" defaultValue="全部" style={{ width: '180px' }}>
                  <Option value={1}>盎司</Option>
                  <Option value={2}>皓之成</Option>
                  <Option value={3}>比高(直充)</Option>
                  <Option value={4}>比高(卡密)</Option>
                  <Option value={5}>琅琅(直充)</Option>
                  <Option value={6}>琅琅(卡密)</Option>
                </Select>
              </Form.Item>
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;商品ID:">
                <Input ref="productId" style={{ width: '180px' }} defaultValue="" />
              </Form.Item>
              <Form.Item label="渠道商品ID:">
                <Input ref="channelProductId" style={{ width: '180px' }} defaultValue="" />
              </Form.Item>
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;商品规格:">
                <Input ref="channelProductItemId" style={{ width: '180px' }} defaultValue="" />
              </Form.Item>
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;运营商:">
                <Input ref="operatorsId" style={{ width: '180px' }} defaultValue="" />
              </Form.Item>
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;充值面值:">
                <Input ref="faceValue" style={{ width: '180px' }} defaultValue="" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" style={{ width: '120px', marginLeft:'80px', marginTop:'30px'}} htmlType="submit" value="1">
                  立即添加
                </Button>
              </Form.Item>
            </Form>
        </Modal>
        <Modal
            title="修改新产品"
            visible={this.state.mvisible}
            onOk={this.hideModifyModal}
            onCancel={this.hideModifyModal}
            okText="修改"
            cancelText="取消"
            width={450}
            footer={[]}
          >
            <Form
              layout="inline"
              onSubmit={this.handleModifySubmit}
              className="components-table-demo-control-bar"
              style={{ marginLeft: 16 }}
            >
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;产品名称:">
                {this.pruductData?this.pruductData.productName:""}
              </Form.Item>
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;渠道:">
                <Select ref="mchannelType" defaultValue="全部" style={{ width: '180px' }}>
                  <Option value={1}>盎司</Option>
                  <Option value={2}>皓之成</Option>
                  <Option value={3}>大地(直充)</Option>
                  <Option value={4}>大地(卡密)</Option>
                  <Option value={5}>琅琅(直充)</Option>
                  <Option value={6}>琅琅(卡密)</Option>
                </Select>
              </Form.Item>
              <Form.Item label="渠道商品ID:">
                <Input ref="mchannelProductId" style={{ width: '180px' }} />
              </Form.Item>
              <Form.Item label="&nbsp;&nbsp;&nbsp;&nbsp;商品规格:">
                <Input ref="mchannelProductItemId" style={{ width: '180px' }} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" style={{ width: '120px', marginLeft:'80px', marginTop:'30px'}} htmlType="submit" value="1">
                  立即修改
                </Button>
              </Form.Item>
            </Form>
        </Modal>
      </>
    )
  }
}

export default ProductList
